<template>
    <div>
        <div class="cribe-title">
            <div class="cribe-repuer">
                <div class="cribe-margin flex">
                    <div class="prop" @click="()=>weekFun(-1)">
                        <i class="iconfont">&#xe62d;</i>
                    </div>
                    <div class="cribe-time flex-1" @click="boolFun">
                        <div v-if="time">{{time}}</div>
                    </div>
                    <div class="next" @click="()=>weekFun(1)" v-if="!bool_end">
                        <i class="iconfont">&#xe62d;</i>
                    </div>
                    <div v-else class="next"></div>
                </div>
            </div>
        </div>
        <div class="shadow" v-if="bool" @click.self="boolFun">
            <div class="white flex flex-x flex-y">
                <div>
                    <div class="white-time flex">
                        <div class="time" @click="()=>startFun(true)">
                            <div class="ash" v-if="start">{{start}}</div>
                            <div class="ash" v-else>开始时间</div>
                        </div>
                        <div class="to">至</div>
                        <div class="time" @click="()=>endFun(true)">
                            <div class="ash" v-if="end">{{end}}</div>
                            <div class="ash" v-else>结束时间</div>
                        </div>
                    </div>
                    <div class="flex flex-x flex-y">
                        <div class="flex">
                            <div class="define" @click="defineFun">确定</div>
                            <div class="reset" @click="reactFun">重置</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="picker-bottom" v-if="startBool">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :formatter="formatter"
                title="开始时间"
                :min-date="minDate"
                :max-date="maxDate"
                item-height='35'
                @cancel="startChange"
                @confirm="startInput"
            />
        </div>
        <div class="picker-bottom" v-if="endBool">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :formatter="formatter"
                title="结束时间"
                :min-date="minDate"
                :max-date="maxDate"
                item-height='35'
                @cancel="endChange"
                @confirm="endInput"
            />
        </div>
    </div>
</template>
<script>
  import { getWeek , dateZero , getDaysInMonth } from "@/utils";
  export default {
    name: "time2",
    data(){
      return {
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(),
        currentDate: new Date(),
        startBool:false,
        endBool:false,
        start:'',
        end:'',
        bool:false,
        time:'',
        bool_end:false
      }
    },
    props: ['returnFun'],
    mounted(){
        this.weekFun(1)
    },
    components: {},
    methods:{
        startFun(bool){
            this.endBool=false;
            this.startBool=bool;
        },
        endFun(bool){
          this.startBool=false
          this.endBool=bool;
        },
        formatter(type, val) {
            if (type === 'year') {
                return val + '年';
            }
            if (type === 'month') {
                return val + '月';
            }
            if (type === 'day') {
                return val + '日';
            }
            return val;
        },
        startChange(){
            this.startBool = !this.startBool;
        },
        endChange(){
            this.endBool = !this.endBool;
        },
        
        defineFun(){
          if(this.start && this.end){
              this.boolFun();
              this.time = `${this.start} 到 ${this.end}`;
              let date_1 = {
                  start1:this.start,
                  end1:this.end
              }
              if(this.returnFun){
                this.returnFun(date_1);
              }
          }
        },
        reactFun(){
          this.start = '';
          this.end = '';
        },
        boolFun(){
          this.startBool=false;
          this.endBool=false;
          this.bool=!this.bool;
        },
        startInput(value){
            let date = new Date(value);
            this.start = `${date.getFullYear()}-${dateZero(date.getMonth()+1)}-${dateZero(date.getDate())}`;
            this.startBool=false;
        },
        endInput(value){
          let date = new Date(value);
          this.end = `${date.getFullYear()}-${dateZero(date.getMonth()+1)}-${dateZero(date.getDate())}`;
          this.endBool=false;
        },
        
        //上一月，下一月
        weekFun(add){
            let time = add>0?this.end:this.start;
            let time_1 = new Date();
            time = time?time:`${time_1.getFullYear()}-${dateZero(time_1.getMonth()+1)}-01`;
            let day_1 = getDaysInMonth(time);
            let day = day_1-time_1.getDate();
            if(add>0){
                day = 0-day;
                this.bool_end = false
            }
            let date_1 = getWeek(time,day);
            this.start = date_1.start1;
            this.end = date_1.end1;
            this.bool_end = date_1.bool;
            this.time = `${date_1.start1} 到 ${date_1.bool?'今天':date_1.end1}`;
            console.log(date_1);
            if(this.returnFun){
                this.returnFun(date_1);
            }
        },
    }
  };
</script>
<style lang="scss" scoped> 
    @import './time';
</style>